@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
   font-family: 'Manrope', sans-serif;
}

.rounded {
   border-radius: 30px !important;
}

.rounded-top {
   border-top-left-radius: 30px !important;
   border-top-right-radius: 30px !important;
}

.round {
   border-radius: 8px;
}

.btn-small {
   padding: 0.4rem !important;
   font-size: 0.8rem !important;
}

.form-control {
   display: block;
   width: 100%;
   height: calc(1.6em + 1.625rem);
   padding: 0.75rem 1rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.6;
   color: #1e2022;
   background-color: #fff;
   background-clip: padding-box;
   border: 0.0625rem solid #e7eaf3;
   border-radius: 0.3125rem;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
   display: inline-block;
   width: 100%;
   height: calc(1.6em + 1.625rem);
   padding: 0.75rem 2rem 0.75rem 1rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.6;
   color: #97a4af;
   vertical-align: middle;
   border: 0.0625rem solid #e7eaf3;
   border-radius: 0.3125rem;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}

.vertical-center {
   position: absolute;
   top: 50%;
   left: 0%;
   transform: translate(0%, -50%);
   -webkit-transform: translate(0%, -50%);
}

.button--primary {
   border-radius: 9999px;
   position: relative;
   color: #fff;
   font-weight: 700;
   display: inline-block;
   line-height: 1.375;
   font-size: 1rem;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-top: 0.5rem;
   padding-bottom: 0.6rem;
   //background-image: linear-gradient(90deg, #009bff, #0062ff);
   backface-visibility: hidden;
   background-image: linear-gradient(to right, #009bff 0%, #0062ff 51%, #009bff 100%);
   transition: all 0.4s ease-out;
   background-size: 200% auto;
   box-shadow: 0 2px 8px 0 rgba(0, 34, 255, 0.08), 0 2px 8px 0 rgba(0, 11, 80, 0.12);
   z-index: 1;
   &:hover {
      transform: translateY(-2px);
      background-position: right center;

      box-shadow: 0 2px 8px 0 rgba(0, 34, 255, 0.322), 0 4px 8px 0 rgba(0, 11, 80, 0.349);
   }
}

.button--primary:before {
   border-radius: 9999px;
   content: '';
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-image: linear-gradient(-90deg, #1aa5ff, #1a72ff);
   z-index: -1;
   transition: opacity 0.3s ease-in-out;
   opacity: 0;
}

.button--primary:hover {
   color: #fff;
}

.shadow {
   box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
}

.price {
   font-size: 5em;
   font-weight: 900;
   margin-top: -20px;
   //color: theme-color('primary');
   background: -webkit-linear-gradient(-90deg, #1aa5ff, #1a72ff);
   -webkit-background-clip: text;
   animation: heartbeat 1.2s infinite;
   -webkit-text-fill-color: transparent;
}

.strikedPrice {
   font-size: 2em;
   margin-bottom: -10px;
   text-decoration: line-through;
}

.iframe-wrap {
   position: relative;
   overflow: hidden;
   width: 100%;
   padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

iframe {
   border-radius: 10px;
   //border: 5px solid #ff9900;
   box-shadow: 0 12px 15px rgba(140, 152, 164, 0.1) !important;
}

.coins {
   background-image: url('../img/coins.png');
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
}

.btn-acelerador {
   background-image: linear-gradient(180deg, #ffb800 0%, #ff9900 100%);
}

.acelerador-header {
   font-size: 3rem;
   line-height: 4rem;
}

.gradient-text {
   background: -webkit-linear-gradient(#ffb800, #ff9900);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.responsive-iframe {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 100%;
}

.priceLabel {
   font-weight: bolder;
   margin-bottom: -10px;
}

td {
   border: 1px solid rgb(218, 218, 218);
   padding: 10px;
}

.ads_noThanks {
   cursor: pointer;
}

.ql-container {
   font-family: Helvetica, Arial, sans-serif;
   font-size: 16px !important;
   height: 90% !important;
}

@import './abstracts/animations';
